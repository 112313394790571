import ILanguage from './ILanguage';

const pt: ILanguage = {
    title: 'Idioma',
    portuguese: 'Português',
    english: 'Inglês',
    spanish: 'Espanhol',
    prev: 'anterior',
    next: 'próxima',
    send: 'Enviar',
    thankYouPageLeftTitle: 'Pesquisa de satisfacao',
    thankYouPageRightTitle: 'Obrigado',
    thankYouPageText: 'Sua opinião é muito importante para nós!',
    loadingSplashScreenText: 'Carregando...',
    of: 'de',
    developedBy: 'Desenvolvido por',
    requiredField: 'Este campo é obrigatório',
    requiredQuestionLabel: '(obrigatório)',
    optionalQuestionLabel: '(opcional)',
    staticClassForSandbox: 'Classes estáticas para ambiente de teste',
    parameterWarningLabel: 'Adicione o nome e valor do parâmetro no fim da URL como "&<name>=<valor>"',
    AutoCompleteLoadingText: 'Carregando, aguarde',
    AutoCompleteNoOptionsText: 'Nenhuma opção disponível',
    AutoCompleteNoResultsText: 'Nenhum resultado encontrado',
    AutoCompleteRetryTitle: 'Tentar novamente?',
    AutoCompleteSearchPromptText: 'Digite para carregar as opções',
    environmentSandbox: 'Formulário teste, apenas para visualização',
    alreadyCompletedForm: 'Você já preencheu este formulário.',
    formUnavailable: 'Este formulário não está disponível',
};

export default pt;
