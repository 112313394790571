
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SplashScreen extends Vue {
    @Prop({ required: true }) public readonly visible!: boolean;

    public delayedVisible: boolean = true;

    @Watch('visible')
    private onVisibleChange(newValue: boolean): void {
        if (!newValue) {
            setTimeout(() => {
                this.delayedVisible = false;
                this.$emit('loaded');
            }, 3000);
        } else {
            this.delayedVisible = true;
        }
    }
}
