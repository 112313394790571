
import { Component, Mixins, Watch } from 'vue-property-decorator';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import { Transaction } from '@/services/FormStructure';

@Component({})
export default class ParameterQuestionComponent extends Mixins(QuestionMixin) {
    public get answer() {
        const parameterValue = this.getParameterValueByPath(this.transactionData, this.componentSettings.parameter);

        if (parameterValue) return parameterValue;
        return null;
    }

    private getParameterValueByPath(transactionData: Transaction, path: string): any {
        return path.split('.').reduce((currentPath, key) => currentPath && currentPath[key], transactionData);
    }

    public get sandboxWarning(): string {
        const message = this.$t('parameterWarningLabel');
        const parameter = this.currentComponent.settings.parameter.replace(/^labels\./, '')
        if (typeof message  === "string") {
            return message.replace('<name>', parameter);
        }
        return '';
    }

    public get showParameterWarningLabel(): boolean {
        return this.currentComponent.type === 'ParameterQuestionComponent' && this.urlData?.environment == 'sandbox' && this.answer === null;
    }

    public mounted() {
        this.setAnswer({ text: this.answer });
    }
}
