import ILanguage from './ILanguage';

const en: ILanguage = {
    title: 'Language',
    portuguese: 'Portuguese',
    english: 'English',
    spanish: 'Spanish',
    prev: 'previous',
    next: 'next',
    send: 'Send',
    thankYouPageLeftTitle: 'Satisfaction survey',
    thankYouPageRightTitle: 'Thanks',
    thankYouPageText: 'Your opinion is very important to us!',
    loadingSplashScreenText: 'Loading...',
    of: 'of',
    developedBy: 'Developed by',
    requiredField: 'Required field',
    requiredQuestionLabel: '(required)',
    optionalQuestionLabel: '(optional)',
    staticClassForSandbox: 'Static classes for testing environment',
    parameterWarningLabel: 'Add parameter name and value at the end of URL like "&<name>=<value>"',
    AutoCompleteLoadingText: 'Loading, please wait',
    AutoCompleteNoOptionsText: 'No option available',
    AutoCompleteNoResultsText: 'No results found',
    AutoCompleteRetryTitle: 'Retry?',
    AutoCompleteSearchPromptText: 'Type to search',
    environmentSandbox: 'Test form, for viewing only',
    alreadyCompletedForm: 'You have already completed this form.',
    formUnavailable: 'This form is not available',
};

export default en;
